var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('client-only',[_c('div',{class:[
      'banner-swiper',
      {
        brief: _vm.type === 'brief',
        blue: _vm.color === 'blue',
      } ]},[(
        (_vm.isMobile &&
          ((_vm.type === 'brief' && _vm.banners.length > 2) ||
            (_vm.type !== 'brief' && _vm.banners.length > 1))) ||
        (!_vm.isMobile && _vm.banners.length > 3)
      )?[_c('div',{directives:[{name:"swiper",rawName:"v-swiper",value:(_vm.swiperOption),expression:"swiperOption"}],ref:"swiper"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.banners),function(banner){return _c('nuxt-link',{key:banner.id,staticClass:"swiper-slide",attrs:{"to":("/" + _vm.link + "/" + (banner.id)),"aria-label":"banner link"}},[_c('img',{staticClass:"bg",attrs:{"src":banner.imageUrl,"alt":"banner"}}),_vm._v(" "),_c('p',{staticClass:"summary"},[_vm._v(_vm._s(banner.summary))]),_vm._v(" "),_c('p',{staticClass:"publish-at"},[_vm._v(_vm._s(banner.publishAt))])])}),1)]),_vm._v(" "),_c('button',{staticClass:"btn-next",attrs:{"aria-label":"next"},on:{"click":_vm.handleBtnNextClick}})]:(
        (_vm.isMobile &&
          ((_vm.type === 'brief' && _vm.banners.length <= 2) ||
            (_vm.type !== 'brief' && _vm.banners.length <= 1))) ||
        (!_vm.isMobile && _vm.banners.length <= 3)
      )?_vm._l((_vm.banners),function(banner){return _c('nuxt-link',{key:banner.id,staticClass:"banner",attrs:{"to":("/" + _vm.link + "/" + (banner.id)),"aria-label":"banner link"}},[_c('img',{staticClass:"bg",attrs:{"src":banner.imageUrl,"alt":"banner"}}),_vm._v(" "),_c('p',{staticClass:"summary"},[_vm._v(_vm._s(banner.summary))]),_vm._v(" "),_c('p',{staticClass:"publish-at"},[_vm._v(_vm._s(banner.publishAt))])])}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }