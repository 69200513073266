//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'Loading',

  props: {
    color: {
      type: String,
      default: 'brown',
    },
  },

  computed: {
    ...mapState(['isLoading']),
  },
};
