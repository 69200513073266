//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'CategoryList',

  props: {
    categoryList: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    onClickHandler: {
      type: Function,
      required: true,
    },
    activeCategory: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: 'brown',
    },
    initialSlide: {
      // for initialSlide
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      // settings for swiper
      swiperOption: {
        spaceBetween: 0,
        speed: 400,
        loop: true,
        shortSwipes: false,
        loopedSlides: this.categoryList.length,
        resizeObserver: true,
        slidesPerView: 3,
        breakpoints: {
          751: {
            slidesPerView: 5,
          },
        },
        on: {
          click: (swiper) => {
            const categoryId = swiper.clickedIndex % this.categoryList.length; // calc which categoryId that user clicked

            this.onClickHandler(categoryId, swiper.activeIndex);
          },
        },
      },
    };
  },

  computed: {
    ...mapState(['isMobile']),
  },

  watch: {
    initialSlide: {
      handler(val) {
        if (this.$swiper && this.$swiper.params) {
          this.$nextTick(() => {
            this.$swiper.slideTo(val % this.categoryList.length, 0);
          });
        }
      },
    },
  },

  methods: {
    handleBtnNextClick() {
      this.$swiper.slideNext();
    },
  },
};
