var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'category-list',
    {
      'full-width': _vm.type === 'full-width',
      blue: _vm.color === 'blue',
    } ]},[(
      (_vm.isMobile && _vm.categoryList.length > 4) ||
      (!_vm.isMobile && _vm.categoryList.length > 7)
    )?[_c('div',{staticClass:"tab-section"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper",value:(_vm.swiperOption),expression:"swiperOption"}],ref:"swiper"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.categoryList),function(category){return _c('swiper-slide',{key:category.id,class:[
              'swiper-slide',
              'section',
              {
                active: category.id === _vm.activeCategory,
              } ]},[_c('div',{staticClass:"tab"},[_vm._v(_vm._s(category.name))])])}),1)])]),_vm._v(" "),_c('button',{staticClass:"btn-next",attrs:{"aria-label":"next"},on:{"click":_vm.handleBtnNextClick}})]:(
      (_vm.isMobile && _vm.categoryList.length <= 4) ||
      (!_vm.isMobile && _vm.categoryList.length <= 7)
    )?_vm._l((_vm.categoryList),function(category){return _c('div',{key:category.id,class:[
        'category',
        {
          active: category.id === _vm.activeCategory,
        } ]},[_c('button',{staticClass:"btn-category",attrs:{"aria-label":"category"},on:{"click":function($event){return _vm.onClickHandler(category.id)}}},[_vm._v("\n        "+_vm._s(category.name)+"\n      ")])])}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }