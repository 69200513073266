//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import utils from '~/utils';

export default {
  name: 'BannerSwiper',

  props: {
    banners: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'default',
    },
  },

  data() {
    return {
      // settings for swiper
      swiperOption: {
        spaceBetween: this.type === 'brief' ? 12 : 28,
        speed: 400,
        loop: true,
        resizeObserver: true,
        lazy: {
          loadPrevNext: true,
        },
        slidesPerView: this.type === 'detail' ? 1 : 2,
        breakpoints: {
          751: {
            slidesPerView: 3,
          },
        },
      },
    };
  },

  computed: {
    ...mapState(['isMobile']),
  },

  mounted() {
    this.$nextTick(() => {
      this.handleResize();
    });
    window.addEventListener('resize', this.handleResize);
  },

  methods: {
    handleBtnNextClick() {
      this.$swiper.slideNext();
    },
    handleResize() {
      if (this.$swiper && this.$swiper.params) {
        const ratio = utils.calcRatio();

        this.$swiper.params.spaceBetween =
          (this.type === 'brief' ? 12 : 28) * ratio;
      }
    },
  },
};
